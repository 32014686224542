// Subway page
document.addEventListener("DOMContentLoaded", function() {

        const {
            successAudio,
            tone1,
            tone2,
            tone3,
            buttonTone,
            failAudio,
            subwayLoop,
            textArray,
            tarantellaRingtone
        } = window.subwayData;

        let phoneNumber = '';
        let currentIndex = 0;
        let isSuccessful = false;
        const modal = document.getElementById('phone-modal');
        const openModalButton = document.getElementById('openphone-modal');
        const phoneNumberField = document.getElementById('phoneNumber');
        const buttonSound = document.getElementById("buttonSound");
        const buttons = document.querySelectorAll(".button-row button");
        window.dataLayer = window.dataLayer || [];

        subwayLoop.loop = true;
        subwayLoop.volume = 0;
        tarantellaRingtone.volume = 0.1;
        function fadeInAudio(audioElement, targetVolume, duration) {
            const initialVolume = audioElement.volume;
            const volumeStep = (targetVolume - initialVolume) / (duration / 50); 

            let currentVolume = initialVolume;
            const fadeInterval = setInterval(() => {
                currentVolume += volumeStep;

                if ((volumeStep > 0 && currentVolume >= targetVolume) || (volumeStep < 0 && currentVolume <= targetVolume)) {
                    clearInterval(fadeInterval);
                    audioElement.volume = targetVolume;
                } else {
                    audioElement.volume = currentVolume;
                }
            }, 50); 
        }
        updateBackground();

        function updateBackground() {
            const phoneBackground = document.querySelector(".Phone-background");
            phoneBackground.style.backgroundImage = `url('${textArray[currentIndex]}')`;
        }


        buttons.forEach(button => {
            button.addEventListener("click", () => {
                buttonTone.currentTime = 0; 
                buttonTone.play();
            });
        });
    
        document.querySelectorAll('.num-button').forEach(button => {
                button.addEventListener('click', (event) => {
                    
                const value = event.target.value;
                
                window.dataLayer.push({
                    event: 'button_click',
                    creative_title: value,
                    creative_type: 'button',
                    creative_placement: 'phone',
                    type: 'button',
                    authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
                });

                if (value == '1' || value == '4' || value == '7' || value == "*") {
                    tone1.currentTime = 0;
                    tone1.play();
                } else if (value == '2' || value == '5' || value == '8' || value == '0') {
                    tone2.currentTime = 0;
                    tone2.play();
                } else if (value == '3' || value == '6' || value == '9' || value == '#') {
                    tone3.currentTime = 0;
                    tone3.play();
                }

                phoneNumber += event.target.value;
                phoneNumberField.value = phoneNumber;
            });
        });

        openModalButton.addEventListener('click', (e) => {
            window.dataLayer.push({
                event: 'button_click',
                creative_title: 'pickup',
                creative_type: 'button',
                creative_placement: 'phone',
                type: 'button',
                authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
            });

            e.preventDefault();
            modal.style.display = 'flex';
            subwayLoop.play();
            fadeInAudio(subwayLoop, 0.1, 3000);
        });

        window.addEventListener('click', (event) => {
            if (event.target === modal) {
                modal.style.display = 'none';
                phoneNumber = '';
                phoneNumberField.value = phoneNumber;
                isSuccessful = false;
            }
        });

        document.getElementById('nextButton').addEventListener('click', () => {
            window.dataLayer.push({
                event: 'button_click',
                creative_title: 'left',
                creative_type: 'button',
                creative_placement: 'phone',
                type: 'button',
                authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
            });
            currentIndex = (currentIndex + 1) % textArray.length;
            updateBackground();

        });

        document.getElementById('backButton').addEventListener('click', () => {
            window.dataLayer.push({
                event: 'button_click',
                creative_title: 'right',
                creative_type: 'button',
                creative_placement: 'phone',
                type: 'button',
                authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
            });
            currentIndex = (currentIndex - 1 + textArray.length) % textArray.length;
            updateBackground();

        });


        document.getElementById('enterButton').addEventListener('click', () => {
            window.dataLayer.push({
                event: 'button_click',
                creative_title: 'middle',
                creative_type: 'button',
                creative_placement: 'phone',
                type: 'button',
                authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
            });
            if (phoneNumber === '15558274828') {
                window.dataLayer.push({
                    event: 'button_click',
                    creative_title: 'success',
                    creative_type: 'button',
                    creative_placement: 'phone',
                    type: 'button',
                    authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0
                });
                successAudio.currentTime = 0;
                successAudio.play();  
                isSuccessful = true;
                failAudio.pause();
                failAudio.currentTime = 0;
                tarantellaRingtone.pause();
                tarantellaRingtone.currentTime = 0;
                setTimeout(() => {
                    isSuccessful = false;
                }, 86000);  
            }else if (phoneNumber === '18005552983'){
                tarantellaRingtone.currentTime = 0;
                tarantellaRingtone.play();
                failAudio.pause();
                failAudio.currentTime = 0;
                successAudio.pause();
                successAudio.currentTime = 0;
                localStorage.setItem('phoneValidation', 'true');
            }else if(!isSuccessful){
                failAudio.currentTime = 0;
                failAudio.play();
                tarantellaRingtone.pause();
                tarantellaRingtone.currentTime = 0;
                successAudio.pause();
                successAudio.currentTime = 0;
            }

            // Big bytes number
            
            phoneNumber = '';
            phoneNumberField.value = phoneNumber;
        });
});